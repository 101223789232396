import { Injectable, NgZone } from "@angular/core";
import {
  MediaResourcePlayer,
  MediaVendor,
  PlayerState,
  MediaResource,
  Track,
  PlayerError,
  PlayerErrorType,
} from "src/app/state/models";
import { YtPlayerApiService } from "./yt-player-api.service";
import {
  YouTubePlayer,
  YtPlayerState,
  YtPlayerReadyEvent,
  YtPlayerStateChangeEvent,
  YtPlayerErrorEvent,
  YtPlayerError,
} from "../yt.model";
import {
  Observable,
  from,
  of,
  Subject,
  Subscription,
  Observer,
  BehaviorSubject,
  ReplaySubject,
  EMPTY,
  interval,
} from "rxjs";
import {
  take,
  catchError,
  filter,
  tap,
  shareReplay,
  retryWhen,
  switchMapTo,
  switchMap,
  map,
  finalize,
  distinctUntilChanged,
} from "rxjs/operators";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class YtPlayerService implements MediaResourcePlayer {
  // PRIVATE STATE
  private _loadingSubject = new BehaviorSubject(false);
  private _loadingDone = this._loadingSubject
    .pipe(
      filter((l) => l),
      take(1),
      tap((l) => (this._loaded = l))
    )
    .toPromise();
  private _loaded: boolean;
  private _player: YouTubePlayer;
  private _playerStateSub: Subscription;
  private _playerErrorSub: Subscription;
  private _playerVolumeSub: Subscription;
  private _playerProgressSub: Subscription;
  private _retrySubject = new Subject<any>();

  // PUBLIC STATE
  public track: Track;
  public vendor: MediaVendor = MediaVendor.YouTube;
  public playerState = new Subject<PlayerState>();
  public playerErrors = new BehaviorSubject<PlayerError>(null);
  public playerVolume = new Subject<number>();
  public playerProgress = new BehaviorSubject<number>(0);

  private get _volume() {
    if (!this._loaded) return 0;
    return this._player.getVolume();
  }
  private get _progress() {
    if (!this._loaded || this._player.getDuration() === 0) return 0;
    if (this.track && this.track.isLive) return 100;
    const percent = this._player.getCurrentTime() / this._player.getDuration();
    return isNaN(percent) ? 0 : Math.round(100 * percent) / 100;
  }

  // PUBLIC METHODS
  public async cue(track: Track): Promise<void> {
    if (!track) return;
    this.track = track;
    await this._loadingDone;
    this._player.cueVideoById(track.vendorId);
  }

  public async play(track: Track): Promise<void> {
    await this._loadingDone;

    if (track) {
      this.track = track;
      this._player.loadVideoById(track.vendorId);
    } else {
      this._player.playVideo();
    }
  }

  public stop(): void {
    if (!this._loaded) return;
    this._player.stopVideo();
  }

  public pause(): void {
    if (!this._loaded) return;
    this._player.pauseVideo();
  }

  public setVolume(vol: number): void {
    if (!this._loaded) return;
    this._player.setVolume(vol);
  }

  public seek(percent: number): void {
    if (!this._loaded || !this.track) return;
    if (this.track.isLive) return;

    const duration = this._player.getDuration();
    if (!duration) return;
    const seekToSec = Math.round(percent * duration);
    this._player.seekTo(seekToSec, true);
  }

  public async startReporting(
    stateObserver: Observer<PlayerState>,
    errorObserver: Observer<PlayerError>,
    volumeObserver: Observer<number>,
    progressObserver: Observer<number>
  ): Promise<void> {
    this._playerErrorSub = this.playerErrors.subscribe(errorObserver);
    await this._loadingDone;
    this._playerStateSub = this.playerState.subscribe(stateObserver);
    this._playerVolumeSub = this.playerVolume.subscribe(volumeObserver);
    this._playerProgressSub = this.playerProgress.subscribe(progressObserver);
  }

  public stopReporting(): void {
    [
      this._playerStateSub,
      this._playerErrorSub,
      this._playerVolumeSub,
      this._playerProgressSub,
    ].forEach((sub) => (sub && !sub.closed ? sub.unsubscribe() : null));
  }

  constructor(private playerApi: YtPlayerApiService, private zone: NgZone) {
    this.zone
      .runOutsideAngular(() => this._initPlayer())
      .pipe(
        take(1),
        retryWhen((errors) => {
          console.log("error", Math.random());
          this.playerState.next(PlayerState.UNAVAILABLE);
          this.playerErrors.next({
            vendor: this.vendor,
            type: PlayerErrorType.LOAD_API_FAIL,
            error: new Error(
              `Unable to create YouTube player. Details: ${JSON.stringify(
                errors
              )}`
            ),
            retry: this._retry,
          });

          return errors.pipe(
            switchMapTo(this._retrySubject),
            tap(() => console.log("retry requested"))
          );
        })
      )
      .subscribe();

    this.zone.runOutsideAngular(() => {
      interval(1000)
        .pipe(
          map(() => this._progress + this._volume),
          distinctUntilChanged(),
          tap((_) =>
            this.zone.run(() => {
              this.playerVolume.next(this._volume);
              this.playerProgress.next(this._progress);
            })
          )
        )
        .subscribe();
    });
  }

  // PRIVATE METHODS
  private _retry = () => this._retrySubject.next();

  private _initPlayer(): Observable<YouTubePlayer> {
    return this.playerApi.createPlayer(environment.youTube.playerId, {
      playerVars: {
        controls: 0,
        disablekb: 0,
        modestbranding: 1,
      },
      events: {
        onReady: this.onPlayerReady.bind(this),
        onStateChange: this.onPlayerStateChange.bind(this),
        onError: this.onError.bind(this),
      },
    });
  }

  private onPlayerReady(event: YtPlayerReadyEvent) {
    this._player = event.target;
    this._loadingSubject.next(true);
    this.playerErrors.next(null);
  }

  private onPlayerStateChange(event: YtPlayerStateChangeEvent) {
    this.zone.run(() => {
      console.log("state changed: ", Math.random());
      switch (event.data) {
        case YtPlayerState.UNSTARTED:
          this.playerState.next(PlayerState.STOPPED);
          break;

        case YtPlayerState.CUED:
          this.playerErrors.next(null);
          this.playerState.next(PlayerState.CUED);
          break;

        case YtPlayerState.BUFFERING:
          this.playerState.next(PlayerState.LOADING);
          break;

        case YtPlayerState.PLAYING:
          this.playerErrors.next(null);
          this.playerState.next(PlayerState.PLAYING);
          break;

        case YtPlayerState.PAUSED:
          this.playerErrors.next(null);
          this.playerState.next(PlayerState.PAUSED);
          break;

        case YtPlayerState.ENDED:
          this.playerState.next(PlayerState.ENDED);
          break;
      }
    });
  }

  private onError(event: YtPlayerErrorEvent) {
    this.zone.run(() => {
      console.log("error happened: ", Math.random());

      const error: PlayerError = {
        vendor: MediaVendor.YouTube,
        type: PlayerErrorType.UNKNOWN,
        error: new Error("Unknow error occured."),
      };

      switch (event.data) {
        case YtPlayerError.INVALID_PARAMETER_VALUES:
          error.type = PlayerErrorType.INVALID_RESOURCE_ID;
          error.error.message = `Requested ${this.vendor} track ID was incorrect`;
          break;

        // THESE TWO FALL THROUGH
        case YtPlayerError.NOT_ALLOWED_IN_EMBED_MODE:
        case YtPlayerError.NOT_EMBEDDABLE:
          error.type = PlayerErrorType.PLAYBACK_NOT_ALLOWED;
          error.error.message = `Playback of requested ${this.vendor} track is not allowed.`;
          break;

        case YtPlayerError.NOT_HTML5_CONTENT:
          error.type = PlayerErrorType.PLAYBACK_ERROR;
          error.error.message = "Playback error encountered.";
          break;

        case YtPlayerError.VIDEO_NOT_FOUND:
          error.type = PlayerErrorType.NONEXISTENT_RESOURCE;
          error.error.message = "Player was unable to find requested resource.";
          break;
      }

      this.playerErrors.next(error);
    });
  }
}
