import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, of } from "rxjs";
import {
  SearchQuery,
  SearchLoadEvent,
  SearchErrorEvent,
  QueryCache,
  MediaResourceType,
  MediaVendor,
  MediaResource,
} from "../models";
import { BackendService } from "../backends/backend.service";
import { catchError, tap, filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  // LOADING INFO
  private _loadingSubject$ = new BehaviorSubject<SearchLoadEvent>(null);
  private _loading$ = this._loadingSubject$
    .asObservable()
    .pipe(filter((e) => !!e));

  // ERROR INFO
  private _errorSubject$ = new BehaviorSubject<SearchErrorEvent>(null);
  private _error$ = this._errorSubject$.asObservable();

  // QUERY CACHE
  private _cache: QueryCache = {};
  private _defaultExpiry = 2419200000; // 4 weeks
  private _cacheId(q: string, v: MediaVendor, t: MediaResourceType): string {
    return `${q}:${v}:${t}`;
  }
  private _isExpired(expiry: number): boolean {
    return Date.now() >= expiry;
  }
  private _isCacheHit(
    q: string,
    v: MediaVendor,
    t: MediaResourceType
  ): boolean {
    const cached = this._cache[this._cacheId(q, v, t)];
    return cached && !this._isExpired(cached.expiresAt);
  }
  private _cacheResults(
    r: MediaResource[],
    q: string,
    v: MediaVendor,
    t: MediaResourceType
  ): void {
    this._cache[this._cacheId(q, v, t)] = {
      expiresAt: Date.now() + this._defaultExpiry,
      results: r,
    };
  }
  private _retrieveFromCache(
    q: string,
    v: MediaVendor,
    t: MediaResourceType
  ): MediaResource[] {
    if (this._isCacheHit(q, v, t))
      return this._cache[this._cacheId(q, v, t)].results;
  }

  constructor(private _backend: BackendService) {}

  // PUBLIC API
  public search({ query, vendor, type, maxResults }: SearchQuery) {
    if (!query || !vendor || !type) return EMPTY;

    // CHECK CACHE
    const cached = this._retrieveFromCache(query, vendor, type);
    if (cached) return of(cached);

    // HIT THE SERVER
    const searchBackend = this._backend.get(vendor).search;
    this._loadingSubject$.next({ loading: true, vendor });
    return searchBackend.search({ query, vendor, type, maxResults }).pipe(
      tap((results) => {
        this._loadingSubject$.next({ loading: false, vendor });
        this._cacheResults(results, query, vendor, type);
      }),
      catchError((error: SearchErrorEvent) => {
        this._loadingSubject$.next({ loading: false, vendor });
        this._errorSubject$.next(error);
        throw error;
      })
    );
  }

  public get loading() {
    return this._loading$;
  }

  public get errors() {
    return this._error$;
  }
}
