import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
})
export class MainPageComponent implements OnInit {
  @ViewChild("bg") bg: ElementRef<HTMLDivElement>;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const bgImg = new Image();
    bgImg.src = "/assets/trees.png";
    bgImg.onload = () => {
      const bg = this.bg.nativeElement;
      bg.style.backgroundImage = `url(${bgImg.src})`;
      bg.style.opacity = "1";
    };
  }
}
