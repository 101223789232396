import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { YtPlayerApiService } from "src/app/state/backends/youTube/yt-player/yt-player-api.service";
import {
  YouTubePlayer,
  YtPlayerReadyEvent,
} from "src/app/state/backends/youTube/yt.model";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "invisible-player",
  templateUrl: "./invisible-player.component.html",
  styleUrls: ["./invisible-player.component.scss"],
})
export class InvisiblePlayerComponent implements OnInit, OnChanges {
  _volume: number;
  @Input() videoId = "";
  @Input() autoplay = 1;
  @Input() set volume(value: number) {
    this._volume = value * 100;
  }
  get volume() {
    return this._volume;
  }

  id = `player_${Math.round(Math.random() * 1000000)}`;
  player: YouTubePlayer;

  constructor(public ytService: YtPlayerApiService) {}

  ngOnChanges(changes) {
    if (changes.volume !== undefined) this.player?.setVolume(this.volume);
  }

  ngOnInit(): void {
    this.ytService.ready$
      .pipe(
        filter((ready) => ready),
        tap(() => {
          this.player = new window["YT"].Player(this.id, {
            playerVars: {
              autoplay: this.autoplay,
              enablejsapi: 1,
            },
            events: {
              onReady: (e: YtPlayerReadyEvent) => {
                this.player.setVolume(this.volume);
                this.player.loadVideoById(this.videoId);
              },
            },
          });
        })
      )
      .subscribe();
  }
}
