export enum MediaVendor {
  YouTube = 'YouTube',
  SoundCloud = 'SoundCloud',
  Spotify = 'Spotify'
}

export enum MediaResourceType {
  Track = 'Track',
  Playlist = 'Playlist'
}

export interface BaseMediaResource {
  id: string;
  vendorId: string;
  vendor: MediaVendor;
  title: string;
  author: string;
  imageUrl: string;
  description?: string;
}

export interface Track extends BaseMediaResource {
  type: MediaResourceType.Track;
  isLive: boolean;
  length: string;
  views?: number;
  likes?: number;
  dislikes?: number;
}

export interface Playlist extends BaseMediaResource {
  type: MediaResourceType.Playlist;
  tracks: Track[];
  length: string;
}

export type MediaResource = Track | Playlist;

export interface SearchQuery {
  query: string;
  vendor: MediaVendor;
  type?: MediaResourceType;
  maxResults?: number;
}

export interface SearchResults {
  type: MediaResourceType;
  list: MediaResource[];
}
