import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  OnChanges,
} from "@angular/core";
import { SchedulerService } from "src/app/state/services/scheduler.service";
import {
  trigger,
  transition,
  query,
  stagger,
  animateChild,
  style,
  animate,
} from "@angular/animations";
import { Track } from "src/app/state/models";

@Component({
  selector: "track-list-small",
  templateUrl: "./pending-songs.component.html",
  styleUrls: ["./pending-songs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("list", [
      transition(":enter", [
        query("@item", stagger(30, animateChild()), { optional: true }),
      ]),
    ]),
    trigger("item", [
      transition(":enter", [
        style({ transform: "scale(0.8)", opacity: 0 }),
        animate(
          "0.4s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class TrackListSmallComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, OnChanges {
  @Input() tracks: Track[];

  constructor(public scheduler: SchedulerService) {}

  ngOnInit() {
    // console.log("started");
  }

  ngOnDestroy() {
    // console.log("destroyed");
  }

  ngAfterViewInit() {
    // console.log("view init", Math.random());
  }

  ngOnChanges(c) {
    // console.log("detected changes: ", c, Math.random());
  }

  ngAfterViewChecked() {
    // console.log('view checked', Math.random());
  }
}
