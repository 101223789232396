import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
  SimpleChanges,
} from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { RaintubeBreakpoints as RtBp } from "src/app/breakpoints";
import { distinctUntilChanged, filter, tap, takeWhile } from "rxjs/operators";
import {
  MediaResourceType,
  MediaResource,
  MediaVendor,
} from "src/app/state/models";
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild,
  AnimationEvent,
} from "@angular/animations";
import { Subject, merge } from "rxjs";
import { SchedulerService } from "src/app/state/services/scheduler.service";

@Component({
  selector: "search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
  animations: [
    trigger("list", [
      transition("* => expand", [query("@item", stagger(30, animateChild()))]),
    ]),
    trigger("item", [
      transition(":enter", [
        style({ transform: "scale(0.8)", opacity: 0 }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class SearchResultsComponent implements OnInit, OnChanges {
  perRow = 1;
  isYt: boolean;
  isSc: boolean;
  breakpoint: string;
  active: MediaResource;
  focused: MediaResource;
  animationState: string;
  alive = true;

  @Input() items: MediaResource[];
  @Input() vendor: MediaVendor;
  @Input() current: MediaResource;
  @Input() playing: boolean;
  @Output() itemClicked = new EventEmitter();
  @Output() resourceRequest = new EventEmitter();
  @Output() itemInfoRequest = new EventEmitter();

  private _itemsChanged = new Subject<boolean>();

  constructor(
    private el: ElementRef<HTMLDivElement>,
    private bpObserver: BreakpointObserver,
    private trackScheduler: SchedulerService
  ) {
    // ADJUST PER ROW WHEN EITHER OF THESE TWO HAPPENS
    const itemsChanged = this._itemsChanged.asObservable();
    const breakpointsChanged = this.bpObserver
      .observe([RtBp.tablet, RtBp.small, RtBp.medium, RtBp.large, RtBp.xlarge])
      .pipe(
        distinctUntilChanged(),
        filter(({ matches: m }) => m),
        tap(({ breakpoints: bp }) => {
          if (bp[RtBp.tablet]) this.breakpoint = "tablet";
          else if (bp[RtBp.small]) this.breakpoint = "small";
          else if (bp[RtBp.medium]) this.breakpoint = "medium";
          else if (bp[RtBp.large]) this.breakpoint = "large";
          else if (bp[RtBp.xlarge]) this.breakpoint = "xlarge";
        })
      );

    merge(breakpointsChanged, itemsChanged)
      .pipe(
        takeWhile(() => this.alive),
        tap(() => {
          this.isYt = this.vendor === MediaVendor.YouTube;
          this.isSc = this.vendor === MediaVendor.SoundCloud;
        })
      )
      .subscribe(this.adjustPerRow.bind(this));
  }

  ngOnInit() {}

  ngOnChanges(c: SimpleChanges) {
    if (c.items && c.items.currentValue) {
      this.animationState = "expand";
      this._itemsChanged.next();
    }
  }

  clicked(item) {
    this.itemClicked.next(item);
  }

  tabChanged(tabChangeEvent) {
    const tab = tabChangeEvent.title;
    const type =
      tab === "Tracks" ? MediaResourceType.Track : MediaResourceType.Playlist;
    this.resourceRequest.emit({ type });
  }

  isLast(index: number) {
    return (index + 1) % this.perRow === 0;
  }

  isCurrent(item: MediaResource) {
    return this.current && item.vendorId === this.current.vendorId;
  }

  setFocused(item, itemEl: HTMLUListElement) {
    setTimeout(() => {
      if (!item && itemEl.contains(document.activeElement)) return;
      this.focused = item;
    }, 100);
  }

  moveFocus(event: KeyboardEvent, itemEl: HTMLUListElement, index: number) {
    switch (event.key) {
      case "ArrowLeft":
        if (index < 1) return;
        focusNext(itemEl, "left");
        break;
      case "ArrowRight":
        if (index >= this.items.length - 1) return;
        focusNext(itemEl, "right");
        break;
    }

    function focusNext(el: HTMLUListElement, direction: "left" | "right") {
      const prevOrNext = direction === "left" ? "previous" : "next";
      const nextSibling: HTMLUListElement = getNext(el, prevOrNext);
      if (nextSibling) nextSibling.focus();

      function getNext(e: HTMLUListElement, prep) {
        let next: HTMLElement = el && el[`${prep}ElementSibling`];
        while (next && next.tagName !== "LI") {
          next = next[`${prep}ElementSibling`];
        }
        return next as HTMLUListElement;
      }
    }
  }

  shouldHover(item: MediaResource) {
    return this.active === item || this.focused === item;
  }

  infoIcon(item: MediaResource) {
    if (item.type === MediaResourceType.Track && item.isLive) return 1;
    return item.type === MediaResourceType.Track ? 2 : 3;
  }

  adjustPerRow() {
    const bpMap = {
      [MediaVendor.YouTube]: {
        tablet: 2,
        small: 3,
        medium: 3,
        large: 4,
        xlarge: 4,
      },
      [MediaVendor.SoundCloud]: {
        tablet: 2,
        small: 3,
        medium: 4,
        large: 5,
        xlarge: 5,
      },
    };
    this.perRow = bpMap[this.vendor]?.[this.breakpoint];
  }

  useBackupImage($event) {
    $event.target.src = "/assets/sc-placeholder.png";
  }

  resetAnimation($event: AnimationEvent) {
    if ($event.toState === "expand" && $event.totalTime > 0) {
      this.animationState = "";
    }
  }

  playSong(item: MediaResource) {
    this.trackScheduler.play(item);
  }

  queueSong(item: MediaResource) {
    this.trackScheduler.enqueue(item);
  }
}
