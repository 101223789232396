<ul role="tablist"  class="subheading-4" #linkList>
  <li role="presentation" *ngFor="let tab of tabs; let i = index"
      (click)="selectTab(tab)" [class.active]="tab.active">
    <a role="tab" href="#"
       (click)="false"
       (keydown)="transitionTab($event, i)"
       [tabindex]="tab.active ? 0 : -1"
       [attr.aria-controls]="tab.tabTitle"
       [attr.aria-selected]="tab.active">{{ tab.tabTitle }}</a>
  </li>
  <div class="underline" [style.left]="activeLeftOffset" [style.width]="activeWidth"></div>
</ul>
<ng-content></ng-content>
