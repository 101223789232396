<div class="content">
  <ng-content></ng-content>
</div>

<svg id="gauge" #gauge width="100%" height="100%">
  <circle
    id="outline"
    #outline
    (click)="respondToMouseEvent($event)"
    [attr.r]="viewRadius"
    cx="50%"
    cy="50%"
    [attr.stroke-width]="viewTrackWidth"
    fill="none"
  ></circle>

  <circle
    id="meter"
    #meter
    (click)="respondToMouseEvent($event)"
    [attr.r]="viewRadius"
    cx="50%"
    cy="50%"
    [attr.stroke-width]="viewTrackWidth"
    fill="none"
    stroke="#2BC4F4"
    stroke-linecap="round"
    [attr.stroke-dasharray]="strokeDasharray"
  ></circle>

  <circle
    id="knob"
    #knob
    [attr.r]="knobRadius"
    [attr.cx]="viewKnobCx"
    [attr.cy]="viewKnobCy"
    fill="#ececec"
    (mousedown)="beginKnobDrag($event)"
  ></circle>
</svg>
