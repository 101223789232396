import { Injectable } from "@angular/core";
import {
  MediaResourcePlayer,
  Track,
  PlayerState,
  PlayerError,
  PlayerErrorType,
} from "../models";
import { BehaviorSubject, Subject } from "rxjs";
import { BackendService } from "../backends/backend.service";
import {
  share,
  map,
  tap,
  distinctUntilChanged,
  shareReplay,
} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  // REMEMBER VOLUME WHEN SWITCHING PLAYERS

  constructor(private _backend: BackendService) {}

  // PLAYER REFERENCE
  private _player: MediaResourcePlayer;

  private get player() {
    // if no current track abort
    if (!this.currentTrack) return;

    // if vendors match, just return current player
    const vendorMatches =
      this._player && this._player.vendor === this.currentTrack.vendor;
    if (vendorMatches) return this._player;

    // if vendors don't match, deregister player notifications from current player
    if (this._player && !vendorMatches) this._player.stopReporting();

    // finally reassign new player, register notifications and set correct volume;
    this._player = this._backend.get(this.currentTrack.vendor).player;

    if (!this._player) {
      this._playerState.next(PlayerState.UNAVAILABLE);
      this._playerErrors.next(<PlayerError>{
        vendor: this.currentTrack.vendor,
        error: new Error(`${this.currentTrack.vendor} player is unavailable`),
        type: PlayerErrorType.UNKNOWN,
        track: this.currentTrack,
      });

      return null;
    }

    this._player.setVolume(this._volume.value);
    this._player.startReporting(
      this._playerState,
      this._playerErrors,
      this._volume,
      this._progress
    );

    return this._player;
  }

  // GET PLAYER STATE
  private _playerState = new BehaviorSubject<PlayerState>(
    PlayerState.UNAVAILABLE
  );

  public playerState = this._playerState
    .asObservable()
    .pipe(distinctUntilChanged(), share());

  private _playerErrors = new BehaviorSubject(null);

  public playerErrors = this._playerErrors
    .asObservable()
    .pipe(distinctUntilChanged(), share());

  public loading: boolean;
  public loading$ = this.playerState.pipe(
    map((state) => state === PlayerState.LOADING),
    tap((x) => (this.loading = x)),
    distinctUntilChanged()
  );

  public playing: boolean;
  public playing$ = this.playerState.pipe(
    map((state) => state === PlayerState.PLAYING),
    tap((x) => (this.playing = x)),
    distinctUntilChanged()
  );

  public currentTrack: Track;

  /** Number between 0 and 1 */
  private _progress = new BehaviorSubject<number>(0);
  public progress$ = this._progress.asObservable().pipe(shareReplay(1));

  /** Number between 0 and 100 */
  private _volume = new BehaviorSubject<number>(38);
  public volume$ = this._volume.asObservable().pipe(shareReplay(1));

  // SET PLAYER STATE
  public cue(track: Track): void {
    if (this.currentTrack && this.player) this.stop();
    this.currentTrack = track;
    this.player
      .cue(track)
      .catch(this.handleError(PlayerErrorType.PLAYBACK_ERROR));
  }

  public play(newTrack?: Track): void {
    const playerLoaded = Boolean(this.player && this.currentTrack);

    // stop old track and play new track
    if (newTrack) {
      if (playerLoaded) this.stop();
      this.currentTrack = newTrack;
      this.player
        .play(newTrack)
        .catch(this.handleError(PlayerErrorType.PLAYBACK_ERROR));

      // if no new track, resume playback of current, if it exists
    } else {
      if (playerLoaded)
        this.player
          .play()
          .catch(this.handleError(PlayerErrorType.PLAYBACK_ERROR));
    }
  }

  public stop(): void {
    if (this.player) this.player.stop();
  }

  public pause(): void {
    if (this.player) this.player.pause();
  }

  public seek(percent: number): void {
    if (this.currentTrack && this.player) this.player.seek(percent);
  }

  public setVolume(vol: number): void {
    if (this.player) this.player.setVolume(vol);
  }

  public clear() {
    this.currentTrack = null;
    this._progress.next(0);
  }

  // PRIVATE HELPER METHODS
  private handleError(errorType: PlayerErrorType = PlayerErrorType.UNKNOWN) {
    return (thrown) =>
      this._playerErrors.next({
        vendor: this.player.vendor,
        type: errorType,
        error: new Error(thrown),
      });
  }
}
