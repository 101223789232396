import { Injectable } from '@angular/core';
import { MediaBackend } from '../../models';
import { ScSearchService } from './sc-search/sc-search.service';

@Injectable({
  providedIn: 'root'
})
export class ScWrapperService implements MediaBackend {

  constructor(private _search: ScSearchService) { }

  get search() {
    return this._search;
  }

  get player() {
    return null;
  }
}
