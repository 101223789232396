import { Component, OnInit, OnDestroy } from "@angular/core";
import { PlayerService } from "src/app/state/services/player.service";
import { environment } from "src/environments/environment";
import { takeWhile, tap, filter } from "rxjs/operators";
import { combineLatest, interval } from "rxjs";
import { trigger, transition, style, animate } from "@angular/animations";
import { PlayerError, PlayerState } from "src/app/state/models";
import { SchedulerService } from "src/app/state/services/scheduler.service";

@Component({
  selector: "player",
  templateUrl: "./player.component.html",
  styleUrls: ["./player.component.scss"],
  animations: [
    trigger("opacityToggle", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("0.12s ease", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("0.12s ease", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class PlayerComponent implements OnInit, OnDestroy {
  ytId = environment.youTube.playerId;
  playing = false;
  loading: boolean;
  alive = true;
  showVolume = false;

  volLevel(vol: number) {
    if (vol > 50) return 3;
    if (vol > 25) return 2;
    if (vol > 0) return 1;
    return 0;
  }

  constructor(
    public player: PlayerService,
    public scheduler: SchedulerService
  ) {
    combineLatest([player.playing$, player.loading$])
      .pipe(takeWhile(() => this.alive))
      .subscribe(([playing, loading]) => {
        this.playing = playing;
        this.loading = loading;
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.alive = false;
  }

  controlPlayback() {
    this.playing ? this.player.pause() : this.player.play();
  }

  setVolume(vol: number) {
    this.player.setVolume(vol);
  }

  setProgress(progress: number) {
    this.player.seek(progress / 100);
  }

  retry(error: PlayerError) {
    if (error.retry) error.retry();
  }
}
