import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MediaVendor } from 'src/app/state/models';

@Component({
  selector: 'vendor-icon',
  templateUrl: './vendor-icon.component.html',
  styleUrls: ['./vendor-icon.component.scss']
})
export class VendorIconComponent implements OnInit {
  @Input() selected: MediaVendor;
  @Output() pickVendor = new EventEmitter<MediaVendor>();

  sc = MediaVendor.SoundCloud;
  yt = MediaVendor.YouTube;

  constructor() { }

  ngOnInit() {
  }

  pick(vendor: MediaVendor) {
    this.pickVendor.emit(vendor);
    return false;
  }
}
