import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "submit-btn",
  templateUrl: "./submit-btn.component.html",
  styleUrls: ["./submit-btn.component.scss"],
})
export class SubmitBtnComponent implements OnInit {
  @Input() loading: boolean;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit() {}
}
