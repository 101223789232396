// YOUTUBE SEARCH RESULT MODELS

export enum YtPart {
  Snippet = "snippet",
  ContentDetails = "contentDetails",
  Statistics = "statistics",
}

export enum YtResource {
  Video = "video",
  Playlist = "playlist",
}

export interface YtItem {
  [key: string]: any;
}

export interface YtItemsResponse {
  items: YtItem[];
}

// YOUTUBE PLAYER MODELS

export enum YtPlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}

export enum YtPlayerError {
  INVALID_PARAMETER_VALUES = 2,
  NOT_HTML5_CONTENT = 5,
  VIDEO_NOT_FOUND = 100,
  NOT_ALLOWED_IN_EMBED_MODE = 101,
  NOT_EMBEDDABLE = 150,
}

export interface YtPlayerReadyEvent {
  target: YouTubePlayer;
}

export interface YtPlayerStateChangeEvent {
  data: YtPlayerState;
}

export interface YtPlayerErrorEvent {
  data: YtPlayerError;
}

export interface YouTubePlayer {
  /**
   * This function loads the specified video's thumbnail and prepares the player to play the video.
   * The player does not request the FLV until playVideo() or seekTo() is called.
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#cueVideoById)
   *
   * @param videoId The required videoId parameter specifies the YouTube Video ID of the video to be played.
   * In the YouTube Data API, a video resource's id property specifies the ID.
   * @param startSeconds The optional startSeconds parameter accepts a float/integer and specifies the time from which
   * the video should start playing when playVideo() is called. If you specify a startSeconds value and
   * then call seekTo(), then the player plays from the time specified in the seekTo() call. When the
   * video is cued and ready to play, the player will broadcast a video cued event (5).
   * @param endSeconds The optional endSeconds parameter, which is only supported in object syntax, accepts a float/integer
   * and specifies the time when the video should stop playing when playVideo() is called. If you specify
   * an endSeconds value and then call seekTo(), the endSeconds value will no longer be in effect.
   * @param suggestedQuality The optional suggestedQuality parameter specifies the suggested playback quality for the video.
   * Please see the definition of the setPlaybackQuality function for more information about playback quality.
   */
  cueVideoById(
    videoId: string,
    startSeconds?: number,
    endSeconds?: number,
    suggestedQuality?: string
  ): void;

  /**
   * This function loads and plays the specified video.
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#loadVideoById)
   *
   * @param videoId The required videoId parameter specifies the YouTube Video ID of the video to be played.
   * In the YouTube Data API, a video resource's id property specifies the ID.
   * @param startSeconds The optional startSeconds parameter accepts a float/integer. If it is specified,
   * then the video will start from the closest keyframe to the specified time.
   * @param endSeconds The optional endSeconds parameter accepts a float/integer. If it is specified, then the
   * video will stop playing at the specified time.
   * @param suggestedQuality The optional suggestedQuality parameter specifies the suggested playback quality for the video.
   * Please see the definition of the setPlaybackQuality function for more information about playback quality.
   */
  loadVideoById(
    videoId: string,
    startSeconds?: number,
    endSeconds?: number,
    suggestedQuality?: string
  ): void;

  /**
   * Plays the currently cued/loaded video. The final player state after this function executes will be playing (1).
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#playVideo)
   *
   * **_Note_**: A playback only counts toward a video's official view count if it is initiated via a native play button in the player.
   */
  playVideo(): void;

  /**
   * Pauses the currently playing video. The final player state after this function executes will be paused (2)
   * unless the player is in the ended (0) state when the function is called, in which case the player
   * state will not change.
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#pauseVideo)
   */
  pauseVideo(): void;

  /**
   *
   * Stops and cancels loading of the current video. This function should be reserved for rare situations when
   * you know that the user will not be watching additional video in the player. If your intent is to pause the
   * video, you should just call the pauseVideo function. If you want to change the video that the player is playing,
   * you can call one of the queueing functions without calling stopVideo first.
   *
   * **_Important_**: Unlike the pauseVideo function, which leaves the player in the paused (2) state, the stopVideo
   * function could put the player into any not-playing state, including ended (0), paused (2), video cued (5) or unstarted (-1).
   */
  stopVideo(): void;

  /**
   * Seeks to a specified time in the video. If the player is paused when the function is called, it will remain paused.
   * If the function is called from another state (playing, video cued, etc.), the player will play the video.
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#stopVideo)
   *
   * @param seconds The seconds parameter identifies the time to which the player should advance. The player will advance
   * to the closest keyframe before that time unless the player has already downloaded the portion of the video to
   * which the user is seeking.
   * @param allowSeekAhead The allowSeekAhead parameter determines whether the player will make a new request to the
   * server if the seconds parameter specifies a time outside of the currently buffered video data.
   * We recommend thatyou set this parameter to false while the user drags the mouse along a video progress bar and then
   * set it to true when the user releases the mouse. This approach lets a user scroll to different points of a video without
   * requesting new video streams by scrolling past unbuffered points in the video. When the user releases the mouse button,
   * the player advances to the desired point in the video and requests a new video stream if necessary.
   */
  seekTo(seconds: number, allowSeekAhead: boolean): void;

  /**
   * Sets the volume.
   * [LINK TO DOCS](https://developers.google.com/youtube/iframe_api_reference#setVolume)
   *
   * @param volume Integer between 0 and 100.
   */
  setVolume(volume: number): void;

  /**
   * Returns the player's current volume, an integer between 0 and 100.
   * Note that getVolume() will return the volume even if the player is muted.
   */
  getVolume(): number;

  /**
   * Returns the duration in seconds of the currently playing video. Note that getDuration() will
   * return 0 until the video's metadata is loaded, which normally happens just after the video starts playing.
   *
   * If the currently playing video is a live event, the getDuration() function will return the elapsed time
   * since the live video stream began. Specifically, this is the amount of time that the video has streamed
   * without being reset or interrupted. In addition, this duration is commonly longer than the actual event
   * time since streaming may begin before the event's start time.
   */
  getDuration(): number;

  /**
   * Returns the elapsed time in seconds since the video started playing.
   */
  getCurrentTime(): number;
}
