<button class="submit-btn" type="submit" [disabled]="loading || disabled">
  <svg
    class="search-icon"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#ECECEC"
      transform="translate(-14 -14)"
      d="M42.2890625,37.7109375 C42.6171891,38.0390641 42.6171891,38.3671859 42.2890625,38.6953125 L39.265625,41.7890625 C39.1249993,41.9296882 38.9375012,42 38.703125,42 C38.5156241,42 38.3515632,41.9296882 38.2109375,41.7890625 L32.3046875,35.7421875 C30.5234286,36.8203179 28.6484473,37.359375 26.6796875,37.359375 C23.6327973,37.359375 21.0078235,36.2695421 18.8046875,34.0898438 C16.6015515,31.9101454 15.5,29.273453 15.5,26.1796875 C15.5,23.085922 16.6015515,20.4492296 18.8046875,18.2695312 C21.0078235,16.0898329 23.6327973,15 26.6796875,15 C29.7265777,15 32.3398329,16.0898329 34.5195312,18.2695312 C36.6992296,20.4492296 37.7890625,23.085922 37.7890625,26.1796875 C37.7890625,28.1484473 37.2968799,29.9765541 36.3125,31.6640625 L42.2890625,37.7109375 Z M31.4609375,21.3984375 C30.1484309,20.0390557 28.5546969,19.359375 26.6796875,19.359375 C24.8046781,19.359375 23.1992254,20.0273371 21.8632812,21.3632812 C20.5273371,22.6992254 19.859375,24.3046781 19.859375,26.1796875 C19.859375,28.0546969 20.5273371,29.6601496 21.8632812,30.9960938 C23.1992254,32.3320379 24.8046781,33 26.6796875,33 C28.5546969,33 30.1484309,32.3320379 31.4609375,30.9960938 C32.7734441,29.6601496 33.4296875,28.0546969 33.4296875,26.1796875 C33.4296875,24.3046781 32.7734441,22.7109441 31.4609375,21.3984375 Z"
      id="ion-search---Ionicons"
    ></path>
  </svg>

  <div class="highlight"></div>
</button>
