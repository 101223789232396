import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  MediaResourceProvider,
  SearchQuery,
  MediaResource,
  MediaResourceType,
} from "../../../models";
import { YtPart, YtResource, YtItemsResponse } from "../yt.model";
import {
  determineYoutubeResourceType,
  formatToMediaResource,
  determineDefaultMaxResults,
  formatVideoDuration,
  formatPlaylistDuration,
} from "./yt.formatters";

@Injectable({
  providedIn: "root",
})
export class YtSearchService implements MediaResourceProvider {
  constructor(private http: HttpClient) {}

  search(query: SearchQuery): Observable<MediaResource[]> {
    const part: YtPart = YtPart.Snippet,
      type: YtResource = determineYoutubeResourceType(query.type),
      q: string = query.query,
      maxResults = `${query.maxResults || determineDefaultMaxResults(type)}`,
      key = environment.youTube.token,
      params = { part, type, maxResults, q, key };

    return this.http
      .get<YtItemsResponse>(`${environment.youTube.url}youtube/v3/search`, {
        params,
      })
      .pipe(
        map((r) => r.items.map((item) => formatToMediaResource(item))),
        switchMap((list: MediaResource[]) =>
          this.addResourceInfo(list, query.type, key)
        )
      );
  }

  addResourceInfo(list: MediaResource[], type: MediaResourceType, key: string) {
    switch (type) {
      case MediaResourceType.Track:
        return this.addVideoInfo(list, key);
      case MediaResourceType.Playlist:
        return this.addPlaylistInfo(list, key);
    }
  }

  addVideoInfo(list: MediaResource[], key: string) {
    const vendorIds = list.map((item) => item.vendorId).join(",");
    return this.http
      .get<YtItemsResponse>(`${environment.youTube.url}/youtube/v3/videos`, {
        params: {
          id: vendorIds,
          part: YtPart.ContentDetails,
          key,
        },
      })
      .pipe(
        map((r) => {
          const durationMap: { [videoId: string]: string } = {};
          r.items.forEach(
            (i) =>
              (durationMap[i.id] = formatVideoDuration(
                i.contentDetails.duration
              ))
          );
          list.forEach((item) => (item.length = durationMap[item.vendorId]));
          return list;
        })
      );
  }

  addPlaylistInfo(list: MediaResource[], key: string) {
    const vendorIds = list.map((item) => item.vendorId).join(",");
    return this.http
      .get<YtItemsResponse>(`${environment.youTube.url}/youtube/v3/playlists`, {
        params: {
          id: vendorIds,
          part: YtPart.ContentDetails,
          key,
        },
      })
      .pipe(
        map((r) => {
          const durationMap: { [videoId: string]: string } = {};
          r.items.forEach(
            (i) =>
              (durationMap[i.id] = formatPlaylistDuration(
                i.contentDetails.itemCount
              ))
          );
          list.forEach((item) => (item.length = durationMap[item.vendorId]));
          return list;
        })
      );
  }
}
