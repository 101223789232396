<!-- TOP BAR -->
<div class="top-bar" [class.top-bar-condensed]="listMode">

  <!-- LOGO -->
  <a class="logo-container" [routerLink]="['/']">
    <img class="logo" src="assets/logo.png" alt="Raintube Logo">
  </a>

  <!-- SEARH -->
  <div class="search-bar-block">
    <h1 class="display-1">Mix music with rain</h1>
    <search-bar [q]="query" [vendor]="vendor" [loading]="loading$ | async"
                (search)="updateSearch($event)" (chooseVendor)="updateSearch($event)"></search-bar>
  </div>

</div>

<!-- RESULTS -->
<div class="search-results-container" *ngIf="results$ | async as items">
  <search-results
    [items]="items"
    [vendor]="vendor"
    [current]="player.currentTrack"
    [playing]="player.playing"
    (resourceRequest)="updateSearch($event)"></search-results>
</div>

<!-- OVERLAY -->
<div class="bg-overlay" [class.shown]="listMode"></div>
