import { Injectable } from '@angular/core';
import { Track, MediaResource, MediaResourceType, PlayerState } from '../models';
import { PlayerService } from './player.service';
import { filter, tap, delay, take, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  queue: Track[] = [];
  history: Track[] = [];
  autoPlay = true;

  constructor(private player: PlayerService) {
    this.player.playerState.pipe(
      filter(state => state === PlayerState.ENDED),
      skipWhile(_ => !this.autoPlay),
    ).subscribe(this.dequeue.bind(this));
  }

  play(resource: MediaResource) {
    if (resource.type === MediaResourceType.Track) {
      this.updateHistory();
      this.player.play(resource);
    }
  }

  enqueue(resource: MediaResource) {
    try {
      if (resource.type === MediaResourceType.Track) {
          if (this.player.playing) this.queue = [...this.queue, resource];
          else this.play(resource);
      }
    } catch(e) {
      console.error(e.message);
    }
  }

  dequeue() {
    if (this.queue.length) {
      const [current, ...rest] = this.queue;
      this.queue = rest;
      this.play(current);
    } else {
      this.updateHistory();
      this.player.clear();
    }
  }

  updateHistory() {
    if (!this.player.currentTrack) return;

    // skip if current track and last track in history are the same
    const lastSongInHistory = this.history[this.history.length - 1];
    const lastHistoryId = lastSongInHistory && lastSongInHistory.vendorId;
    const currentAndLastAreTheSame = lastHistoryId && this.player.currentTrack.vendorId === lastHistoryId;
    if (currentAndLastAreTheSame) return;

    // add the track
    this.history = [this.player.currentTrack, ...this.history];
  }
}
