import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
  HostBinding,
  HostListener,
  Input,
} from "@angular/core";
import { fromEvent } from "rxjs";
import { debounceTime, takeWhile } from "rxjs/operators";

@Component({
  selector: "round-slider",
  templateUrl: "./round-slider.component.html",
  styleUrls: ["./round-slider.component.scss"],
})
export class RoundSliderComponent implements OnInit, OnDestroy {
  @Output() change = new EventEmitter<number>();

  @ViewChild("outline") outline: ElementRef<SVGCircleElement>;
  @ViewChild("meter") meter: ElementRef<SVGCircleElement>;
  @ViewChild("knob") knob: ElementRef<SVGCircleElement>;

  private _alive = true;
  private _isDragging: boolean;

  get containerWidth() {
    const adjustment = Math.max(this.knobRadius, this.trackWidth / 2);
    return 2 * (this.radius + adjustment);
  }

  get containerHeight() {
    return this.containerWidth;
  }

  @HostBinding("style.width")
  @HostBinding("style.height")
  get viewWidthHeight() {
    return `${this.containerWidth}px`;
  }

  get containerCenterX() {
    return this.containerWidth / 2;
  }

  get containerCenterY() {
    return this.containerHeight / 2;
  }

  private _containerOffsetLeft: number;
  get containerOffsetLeft() {
    if (this.container && !this._containerOffsetLeft) {
      this._containerOffsetLeft = 0;
      let curr = this.container.nativeElement as HTMLElement;

      while (curr && !isNaN(curr.offsetLeft)) {
        this._containerOffsetLeft += curr.offsetLeft;
        curr = curr.offsetParent as HTMLElement;
      }
    }
    return this._containerOffsetLeft;
  }

  private _containerOffsetTop: number;
  get containerOffsetTop() {
    if (this.container && !this._containerOffsetTop) {
      this._containerOffsetTop = 0;
      let curr = this.container.nativeElement as HTMLElement;

      while (curr && !isNaN(curr.offsetTop)) {
        this._containerOffsetTop += curr.offsetTop;
        curr = curr.offsetParent as HTMLElement;
      }
    }
    return this._containerOffsetTop;
  }

  get absContainerCenterTop() {
    return this.containerOffsetTop + this.containerCenterY;
  }

  get absContainerCenterLeft() {
    return this.containerOffsetLeft + this.containerCenterX;
  }

  @Input() knobRadius = 10;
  get viewKnobRadius() {
    return `${this.knobRadius}px`;
  }

  @Input() trackWidth = this.knobRadius;
  get viewTrackWidth() {
    return `${this.trackWidth}px`;
  }

  @Input() radius = 200;
  get viewRadius() {
    return `${this.radius}px`;
  }

  // KNOB COORDS
  private knobCx: number;
  get viewKnobCx() {
    return !isNaN(this.knobCx) ? `${this.knobCx}px` : "50%";
  }

  private knobCy: number;
  get viewKnobCy() {
    return !isNaN(this.knobCy) ? `${this.knobCy}px` : this.knobRadius;
  }

  // PERCENT DRIVES THE UI
  private _percent = 0;
  get percent() {
    return this._percent;
  }

  @Input("value")
  set percent(val: number) {
    const isLargeChange = Math.abs(this._percent - val) > 0.5;
    const changeAllowed =
      !isLargeChange || (isLargeChange && !this._isDragging);

    if (changeAllowed) {
      if (val < 0) {
        val = 0;
      }
      if (val > 1) {
        val = 1;
      }
      this._percent = val;
      this.change.emit(this._percent);
      this.moveKnob();
    }
  }

  // METER FILL UP
  get strokeDasharray() {
    const circumference = 2 * Math.PI * this.radius;
    const progress = this.percent * circumference;
    return `${progress}, ${circumference}`;
  }

  // ACCESSIBILITY
  @HostBinding("tabindex") tabindex = 0;
  @HostBinding("attr.role") role = "slider";
  @HostBinding("attr.aria-valuemin") rangeStart = 0;
  @HostBinding("attr.aria-valuemax") rangeEnd = 1;
  @HostBinding("attr.aria-valuenow")
  get ariaValue() {
    return this.percent;
  }

  @Input() disabled = false;
  @HostBinding("attr.aria-disabled")
  get ariaDisabled() {
    return this.disabled;
  }

  @HostListener("keydown", ["$event"])
  respondToKeyboard(e: KeyboardEvent) {
    let step: number;
    switch (e.key) {
      case "ArrowDown":
      case "ArrowLeft":
        step = -0.01;
        break;
      case "PageUp":
        step = 0.1;
        break;
      case "PageDown":
        step = -0.1;
        break;
      case "Home":
        step = -1;
        break;
      case "End":
        step = 1;
        break;
      case "ArrowUp":
      case "ArrowRight":
      default:
        step = 0.01;
    }
    this.percent += step;
  }

  // METHOD DEFINITIONS
  beginKnobDrag(e: MouseEvent) {
    this._isDragging = true;
    document.addEventListener("mousemove", this.respondToMouseEvent);
    document.addEventListener("mouseup", this.removeKnobListeners);
  }

  respondToMouseEvent = (e: MouseEvent) => {
    this.percent = this.getPercentFromMouseCoords(e.clientX, e.clientY);
  };

  removeKnobListeners = () => {
    this._isDragging = false;
    document.removeEventListener("mousemove", this.respondToMouseEvent);
    document.removeEventListener("mouseup", this.removeKnobListeners);
  };

  moveKnob = () => {
    [this.knobCx, this.knobCy] = this.getKnobCoordsFromPercent();
  };

  flushContainerData = (_: any) => {
    this._containerOffsetLeft = undefined;
    this._containerOffsetTop = undefined;
  };

  // HEAVY LIFTING HAPPENSE BELOW
  getPercentFromMouseCoords(mouseX: number, mouseY: number) {
    const lenX = this.absContainerCenterLeft - mouseX;
    const lenY = this.absContainerCenterTop - mouseY;
    const lenHyp = Math.sqrt(Math.pow(lenX, 2) + Math.pow(lenY, 2));
    const alpha = Math.acos(lenX / lenHyp);

    const xCoord = -this.radius * Math.cos(alpha);
    const yCoord = (lenY <= 0 ? -1 : 1) * this.radius * Math.sin(alpha);

    let aRad = 0;
    let percent = 0;

    if (xCoord > 0 && yCoord > 0) {
      aRad = Math.atan(xCoord / yCoord);
      percent = aRad / (Math.PI * 2);
    }

    if (xCoord > 0 && yCoord < 0) {
      aRad = Math.atan(yCoord / xCoord);
      percent = 0.25 - aRad / (Math.PI * 2);
    }

    if (xCoord < 0 && yCoord < 0) {
      aRad = Math.atan(xCoord / yCoord);
      percent = 0.5 + aRad / (Math.PI * 2);
    }

    if (xCoord < 0 && yCoord > 0) {
      aRad = Math.atan(yCoord / xCoord);
      percent = 0.75 - aRad / (Math.PI * 2);
    }

    return Number(percent.toFixed(2));
  }

  getKnobCoordsFromPercent() {
    const degrees = (this._percent * 100 * 3.6) % 90;
    const rateFn =
      this._percent <= 0.25 || (this._percent > 0.5 && this._percent <= 0.75)
        ? "sin"
        : "cos";

    let rate = Math[rateFn]((degrees * Math.PI) / 180);
    if (new Set([0.25, 0.75]).has(this._percent)) {
      rate = 1;
    }
    if (new Set([0, 1, 0.5]).has(this._percent)) {
      rate = 0;
    }

    const x = rate * this.radius;
    const y = Math.sqrt(Math.pow(this.radius, 2) - Math.pow(x, 2));

    const knobX = this.containerCenterX + (this._percent < 0.5 ? x : -x);
    const knobY =
      this.containerCenterY -
      (this._percent < 0.25 || this._percent > 0.75 ? y : -y);

    return [knobX, knobY];
  }

  constructor(private container: ElementRef<HTMLDivElement>) {
    // KEEP POSITIONING VARIABLES UP TO DATE
    fromEvent(window, "resize")
      .pipe(
        debounceTime(200),
        takeWhile(() => this._alive)
      )
      .subscribe(this.flushContainerData);
  }

  ngOnInit() {
    this.change.emit(this._percent);
  }

  ngOnDestroy() {
    this._alive = false;
  }
}
