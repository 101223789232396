<player></player>

<invisible-player videoId="biqyq0PTEUM" [volume]="rain"></invisible-player>
<invisible-player videoId="ZvSwho5CRTo" [volume]="thunder"></invisible-player>
<invisible-player videoId="pdd9b47x67M" [volume]="fire"></invisible-player>

<div class="sliders">
  <round-slider
    (change)="rain = $event"
    [radius]="43"
    [trackWidth]="4"
    [knobRadius]="6"
    [value]="rain"
  >
    <svg
      id="rain"
      viewBox="0 0 46 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Desktop-HD" transform="translate(-1129.000000, -380.000000)">
          <g id="playlist" transform="translate(1079.000000, -1.000000)">
            <g id="Group-7" transform="translate(29.000000, 356.000000)">
              <g id="Group-10">
                <g
                  id="iconfinder_weather-5_1214001"
                  transform="translate(22.000000, 26.000000)"
                >
                  <path
                    d="M18.7734228,0.0120731707 C12.3242033,0.0120731707 7.09669919,4.95795935 7.09669919,11.0606341 C7.09669919,11.6523984 7.14597561,12.2336992 7.24068293,12.7996179 C3.18543902,13.2777154 0.0360406504,16.8162276 0.0360406504,21.109 C0.0360406504,25.7270325 3.68258537,29.4719512 8.18095935,29.4719512 L20.1666667,27.9481382 L38.0381789,29.4719512 C41.3117967,29.4719512 43.9639593,27.1037317 43.9639593,24.1810407 C43.9639593,21.5393415 41.7956179,19.3489106 38.9608374,18.9539837 C38.9608374,14.3343415 35.3315528,10.0453252 30.8331789,10.0453252 C30.688748,10.0452358 30.5443171,10.0490813 30.4028374,10.0565935 C29.8665203,4.42539837 24.8642927,0.0120731707 18.7734228,0.0120731707 Z"
                    id="Path"
                    fill="#888888"
                  ></path>
                  <path
                    d="M26.0328862,10.0565935 C25.5736585,4.42539837 21.3407154,-0.0777154472 16.1395935,0.294227642 C12.868122,0.528178862 7.09669919,4.95786992 7.09669919,11.0606341 C7.09669919,11.6523984 7.24068293,12.7996179 7.24068293,12.7996179 C3.47778862,13.137935 0.0360406504,16.8162276 0.0360406504,21.109 C0.0360406504,25.7270325 3.15780488,29.4719512 7.00878862,29.4719512 L32.569122,29.4719512 C35.3718862,29.4719512 37.6422683,27.1037317 37.6422683,24.1810407 C37.6422683,21.5393415 35.7860407,19.3489106 33.3591545,18.9539837 C33.3591545,14.3343415 30.2520569,10.0453252 26.4009837,10.0453252 C26.2775691,10.0452358 26.1538862,10.0490813 26.0328862,10.0565935 C23.9931463,10.1848374 22.1859268,11.3626423 20.9794146,13.1486667"
                    id="Path"
                    fill="#717171"
                  ></path>
                  <path
                    d="M31.7926829,29.4719512 L38.0381789,29.4719512 C41.3117967,29.4719512 43.9639593,27.1037317 43.9639593,24.1810407 C43.9639593,21.5393415 41.7956179,19.3489106 38.9608374,18.9539837 C38.9608374,14.3343415 34.9012114,10.0566829 30.4029268,10.0566829 C30.2585854,10.0566829 30.5444065,10.0491707 30.4029268,10.0566829 C28.0203984,10.1849268 25.9093821,11.3627317 24.5000407,13.1487561"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M15.9186992,29.4772358 L26.6504065,29.4772358"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M30.4028374,10.0565935 C29.8665203,4.42539837 24.8642927,0.0120731707 18.7734228,0.0120731707 C12.3242033,0.0120731707 7.09669919,4.95795935 7.09669919,11.0606341 C7.09669919,11.6523984 7.14597561,12.2336992 7.24068293,12.7996179 C3.18543902,13.2777154 0.0360406504,16.8162276 0.0360406504,21.109 C0.0360406504,25.7270325 3.68258537,29.4719512 8.18095935,29.4719512 L10.8658537,29.4719512"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <g
                    id="Group"
                    transform="translate(9.658537, 29.601626)"
                    stroke="#ECECEC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <path
                      d="M5.2849187,9.3305935 L4.59844715,10.9878374"
                      id="Path"
                    ></path>
                    <path
                      d="M1.66878049,5.55795122 L0.0602764228,9.44121951"
                      id="Path"
                    ></path>
                    <path
                      d="M9.1288374,0.0504390244 L7.26992683,4.53843902"
                      id="Path"
                    ></path>
                    <path
                      d="M3.94121951,0.0719918699 L3.65378862,0.765707317"
                      id="Path"
                    ></path>
                    <path
                      d="M12.4944797,4.42772358 L10.8859756,8.3110813"
                      id="Path"
                    ></path>
                    <path
                      d="M16.1106179,8.20036585 L14.9580325,10.9830976"
                      id="Path"
                    ></path>
                    <path
                      d="M19.4775122,0.0719918699 L18.027122,3.57347967"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </round-slider>

  <round-slider
    (change)="thunder = $event"
    [radius]="43"
    [trackWidth]="4"
    [knobRadius]="6"
    [value]="thunder"
  >
    <svg
      id="thunder"
      viewBox="0 0 46 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Desktop-HD" transform="translate(-1237.000000, -378.000000)">
          <g id="playlist" transform="translate(1079.000000, -1.000000)">
            <g id="Group-7" transform="translate(29.000000, 356.000000)">
              <g id="Group-10" transform="translate(110.000000, 0.000000)">
                <g
                  id="iconfinder_weather-8_1213998"
                  transform="translate(20.000000, 24.000000)"
                >
                  <path
                    d="M30.4028374,10.101935 C29.8664309,4.47073984 24.8642033,0.0574146341 18.7734228,0.0574146341 C12.3242033,0.0574146341 7.09669919,5.00339024 7.09669919,11.1059756 C7.09669919,11.6977398 7.14597561,12.2791301 7.24068293,12.8450488 C3.18543902,13.3231463 0.0360406504,16.8616585 0.0360406504,21.1544309 C0.0360406504,25.7725528 3.68258537,29.5174715 8.18095935,29.5174715 L19.8536585,28.0813008 L38.0381789,29.5174715 C41.3117967,29.5174715 43.9638699,27.1491626 43.9638699,24.226561 C43.9638699,21.5848618 41.7955285,19.394252 38.9608374,18.9993252 C38.9608374,14.3796829 35.3315528,10.0906667 30.8330894,10.0906667 C30.688748,10.0906667 30.5443171,10.0945122 30.4028374,10.101935"
                    id="Path"
                    fill="#888888"
                  ></path>
                  <path
                    d="M26.0327967,10.101935 C25.5735691,4.47073984 21.3407154,-0.0323739837 16.1395041,0.339658537 C12.8680325,0.573609756 7.09669919,5.00339024 7.09669919,11.1059756 C7.09669919,11.6977398 7.24068293,12.8450488 7.24068293,12.8450488 C3.47778862,13.1832764 0.0360406504,16.8616585 0.0360406504,21.1544309 C0.0360406504,25.7725528 3.15780488,29.5174715 7.00878862,29.5174715 L32.569122,29.5174715 C35.3718862,29.5174715 37.6422683,27.1491626 37.6422683,24.226561 C37.6422683,21.5848618 35.7860407,19.394252 33.3591545,18.9993252 C33.3591545,14.3796829 30.2521463,10.0906667 26.4009837,10.0906667 C26.2774797,10.0906667 26.1537967,10.0945122 26.0327967,10.101935 C23.9931463,10.2301789 22.1858374,11.4080732 20.9794146,13.1940976"
                    id="Path"
                    fill="#646464"
                  ></path>
                  <path
                    d="M38.0381789,29.5174715 C41.3117967,29.5174715 43.9638699,27.1491626 43.9638699,24.226561 C43.9638699,21.5848618 41.7955285,19.394252 38.9608374,18.9993252 C38.9608374,14.3796829 34.901122,10.1020244 30.4029268,10.1020244 C30.2584959,10.1020244 30.5444065,10.0945122 30.4029268,10.1020244 C28.0203984,10.2302683 25.9093821,11.4081626 24.4999512,13.194187"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M30.4028374,10.101935 C29.8664309,4.47073984 24.8642033,0.0574146341 18.7734228,0.0574146341 C12.3242033,0.0574146341 7.09669919,5.00339024 7.09669919,11.1059756 C7.09669919,11.6977398 7.14597561,12.2791301 7.24068293,12.8450488 C3.18543902,13.3231463 0.0360406504,16.8616585 0.0360406504,21.1544309 C0.0360406504,25.7725528 3.68258537,29.5174715 8.18095935,29.5174715 L11.7154472,29.5174715"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <g id="Group" transform="translate(12.878049, 24.146341)">
                    <polygon
                      id="Path"
                      fill="#616161"
                      points="13.9834146 4.00730894 16.0682276 4.00730894 12.3100732 12.1617073 12.8290407 6.35290244 9.62294309 6.35290244 11.9114797 0.065195122 15.4181545 0.065195122"
                    ></polygon>
                    <polygon
                      id="Path"
                      fill="#888888"
                      points="13.9832358 4.00730894 16.0679593 4.00730894 12.3098049 12.1617073 12.8288618 6.35290244 11.6873659 6.35290244 14.0791951 0.065195122 15.4178862 0.065195122"
                    ></polygon>
                    <polygon
                      id="Path"
                      stroke="#ECECEC"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      points="13.9834146 4.0074878 16.0679593 4.0074878 12.3099837 12.1617073 12.8291301 6.35290244 9.62303252 6.35290244 11.9112114 0.0652845528 15.4182439 0.065195122"
                    ></polygon>
                    <polygon
                      id="Path"
                      fill="#616161"
                      points="4.36503252 8.24338211 6.44966667 8.24338211 2.69160163 16.3977805 3.21056911 10.5889756 0.00438211382 10.5889756 2.29300813 4.30126829 5.79968293 4.30126829"
                    ></polygon>
                    <polygon
                      id="Path"
                      fill="#888888"
                      points="4.36494309 8.24338211 6.44966667 8.24338211 2.6915122 16.3977805 3.21047967 10.5889756 2.06898374 10.5889756 4.46081301 4.30126829 5.7995935 4.30126829"
                    ></polygon>
                    <polygon
                      id="Path"
                      stroke="#ECECEC"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      points="4.36503252 8.24338211 6.44966667 8.24338211 2.69160163 16.3977805 3.21047967 10.5889756 0.00438211382 10.5889756 2.2929187 4.30126829 5.79968293 4.30126829"
                    ></polygon>
                  </g>
                  <path
                    d="M31.703252,29.5227561 L37.6421789,29.5227561"
                    id="Path"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </round-slider>

  <round-slider
    (change)="fire = $event"
    [radius]="43"
    [trackWidth]="4"
    [knobRadius]="6"
    [value]="fire"
  >
    <svg
      id="fire"
      viewBox="0 0 33 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Desktop-HD"
          transform="translate(-1354.000000, -376.000000)"
          fill-rule="nonzero"
        >
          <g id="playlist" transform="translate(1079.000000, -1.000000)">
            <g id="Group-7" transform="translate(29.000000, 356.000000)">
              <g id="Group-10" transform="translate(220.000000, 0.000000)">
                <g id="Group-2" transform="translate(27.000000, 22.000000)">
                  <path
                    d="M15.571707,23.9268287 C17.8871217,23.9268287 19.7641361,25.8038431 19.7641361,28.1192577 L19.7641361,39.6397624 C19.7641361,41.955177 17.8871217,43.8321914 15.571707,43.8321914 C13.2562924,43.8321914 11.379278,41.955177 11.379278,39.6397624 L11.379278,28.1192577 C11.379278,25.8038431 13.2562924,23.9268287 15.571707,23.9268287 Z"
                    id="Rectangle"
                    stroke="#ECECEC"
                    stroke-width="2"
                    fill="#4D4D4D"
                    transform="translate(15.571707, 33.879510) rotate(7.000000) translate(-15.571707, -33.879510) "
                  ></path>
                  <path
                    d="M7.58493749,21.2694808 C9.90035211,21.2694808 11.7773665,23.1464952 11.7773665,25.4619098 L11.7773665,40.0360423 C11.7773665,42.351457 9.90035211,44.2284714 7.58493749,44.2284714 C5.26952288,44.2284714 3.39250847,42.351457 3.39250847,40.0360423 L3.39250847,25.4619098 C3.39250847,23.1464952 5.26952288,21.2694808 7.58493749,21.2694808 Z"
                    id="Rectangle"
                    stroke="#ECECEC"
                    stroke-width="2"
                    fill="#4D4D4D"
                    transform="translate(7.584937, 32.748976) rotate(26.000000) translate(-7.584937, -32.748976) "
                  ></path>
                  <path
                    d="M21.4649656,22.433651 C23.7803802,22.433651 25.6573946,24.3106654 25.6573946,26.62608 L25.6573946,41.2002125 C25.6573946,43.5156271 23.7803802,45.3926415 21.4649656,45.3926415 C19.1495509,45.3926415 17.2725365,43.5156271 17.2725365,41.2002125 L17.2725365,26.62608 C17.2725365,24.3106654 19.1495509,22.433651 21.4649656,22.433651 Z"
                    id="Rectangle"
                    stroke="#ECECEC"
                    stroke-width="2"
                    fill="#4D4D4D"
                    transform="translate(21.464966, 33.913146) rotate(-40.000000) translate(-21.464966, -33.913146) "
                  ></path>
                  <path
                    d="M4.16403785,25.4006309 C4.99684543,19.4321767 4.36363982,15.6096287 11.9166667,14.6666667 C14.8914628,14.2952765 19.25,9.16666667 18.3333333,2.15148607 C18.3145274,2.00756603 15.2135764,9.19979795 13.1041736,9.54028123 C5.13564669,10.8264984 -0.277602524,14.4353312 2.16785872,21.7720352 C2.31382528,22.209954 2.97921832,23.4194859 4.16403785,25.4006309 Z"
                    id="Path-3"
                    fill="#888888"
                  ></path>
                  <path
                    d="M3.60883281,25.2618297 C3.05362776,24.7066246 0.477905492,21.7604559 1.24921136,18.044164 C2.77602524,10.6876972 8.93307241,11.0412366 12.9085174,9.71608833 C17.488959,8.18927445 17.9053628,1.52681388 17.6277603,2.84217094e-14"
                    id="Path-2"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M17.6941712,0.138801262 C21.9324383,2.77602524 21.9324383,6.44354591 20.9589905,9.29968454 C20.2966904,11.2429022 19.5668947,12.8857221 20.2966904,14.1697048 C21.2365931,15.8233438 22.9974431,15.4069401 23.873817,14.5741325 C25.2118895,13.3025783 24.1021585,10.5002764 25.4006309,11.5205047 C26.3722397,12.6309148 27.6672483,17.3122207 27.7602524,17.6277603 C29.2569655,22.7057351 27.3438486,25.6782334 25.6782334,27.7602524"
                    id="Path-5"
                    stroke="#ECECEC"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </round-slider>
</div>

<tabs (tabChange)="tabChanged()">
  <tab tabTitle="Queue"
    ><track-list-small [tracks]="scheduler.queue"></track-list-small
  ></tab>
  <tab tabTitle="History"
    ><track-list-small [tracks]="scheduler.history"></track-list-small
  ></tab>
</tabs>
