import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  tap,
  filter,
  takeWhile,
  delayWhen,
  switchMap,
  map,
  share,
  catchError,
} from "rxjs/operators";
import { trigger, transition, style, animate } from "@angular/animations";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { of, timer, BehaviorSubject, Observable, EMPTY, from } from "rxjs";
import { listModeDelay } from "../breakpoints";
import { SearchService } from "../state/services/search.service";
import { MediaVendor, MediaResourceType, MediaResource } from "../state/models";
import { SchedulerService } from "../state/services/scheduler.service";
import { PlayerService } from "../state/services/player.service";

@Component({
  selector: "main-content",
  templateUrl: "./main-content.component.html",
  styleUrls: ["./main-content.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate("0.2s ease", style({ opacity: "*" })),
      ]),
      transition("* => void", [
        style({ opacity: "*" }),
        animate("0.2s ease", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MainContentComponent implements OnInit, OnDestroy {
  // SEARCH QUERY STATE
  query: string = undefined;
  vendor: MediaVendor = MediaVendor.YouTube;
  type: MediaResourceType = MediaResourceType.Track;

  // UI STATE
  alive = true;
  listMode = false;
  prevListMode = false;

  // SEARCH RESULTS
  _results$ = new BehaviorSubject<MediaResource[]>([]);
  results$ = this._results$.asObservable().pipe(share());
  loading$: Observable<boolean> = of(false);

  constructor(
    public searchService: SearchService,
    public scheduler: SchedulerService,
    public player: PlayerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loading$ = this.searchService.loading.pipe(
      filter((e) => e.vendor === this.vendor),
      map((e) => e.loading)
    );

    this.searchService.errors
      .pipe(
        takeWhile(() => this.alive),
        filter((error) => !!error),
        tap((error) => console.log(error.message))
      )
      .subscribe();
  }

  ngOnInit() {
    const res = this.route.queryParams.pipe(
      takeWhile(() => this.alive),
      filter(this.clearIfNoParams.bind(this)),
      switchMap(({ query, type, vendor }) => {
        this.query = query;
        if (type && MediaResourceType[type]) this.type = type;
        if (vendor && MediaVendor[vendor]) this.vendor = vendor;

        return this.searchService.search({
          query: this.query,
          type: this.type,
          vendor: this.vendor,
        });
      }),
      tap((results) => {
        this.prevListMode = this.listMode;
        this.listMode = results.length > 0;
      }),
      delayWhen(() =>
        timer(this.prevListMode && this.listMode ? 0 : listModeDelay)
      )
    );

    res
      .pipe(
        catchError((_, obs) =>
          from(
            this.updateSearch({
              query: undefined,
              type: this.type,
              vendor: this.vendor,
            })
          ).pipe(switchMap(() => obs))
        )
      )
      .subscribe((results) => this._results$.next(results));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  updateSearch(queryParams: Params) {
    return this.router.navigate([], {
      queryParams: {
        query: this.query,
        vendor: this.vendor,
        type: this.type,
        ...queryParams,
      },
      queryParamsHandling: "merge",
    });
  }

  clearIfNoParams({ query, vendor, type }) {
    const shouldClear = !query && !vendor && !type;
    if (shouldClear) {
      this._results$.next([]);
      this.listMode = false;
      this.query = undefined;
      this.type = MediaResourceType.Track;
    }
    return !shouldClear;
  }
}
