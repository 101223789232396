export const environment = {
  production: true,
  youTube: {
    url: "https://www.googleapis.com/",
    token: "AIzaSyDXV4nbUTg2-e82Q4lJj_sy1qzJz-jKuUU",
    playerId: "yt-player",
  },
  soundCloud: {
    url: "https://api.soundcloud.com",
    clientId: "39b75ad74b79654260a694714bfed5e5",
    secret: "",
  },
};
