import { Injectable } from '@angular/core';
import { YtWrapperService } from './youTube/yt-wrapper.service';
import { MediaVendor, MediaBackend } from '../models';
import { ScWrapperService } from './soundCloud/sc-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private [MediaVendor.YouTube]: MediaBackend;
  private [MediaVendor.SoundCloud]: MediaBackend;
  private [MediaVendor.Spotify]: MediaBackend;

  constructor(
    private ytWrapper: YtWrapperService,
    private scWrapper: ScWrapperService
  ) {
    this[MediaVendor.YouTube] = this.ytWrapper;
    this[MediaVendor.SoundCloud] = this.scWrapper;
  }

  get(vendor: MediaVendor): MediaBackend {
    return this[MediaVendor[vendor]];
  }
}
