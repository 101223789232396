import {
  MediaResourceType,
  MediaVendor,
  MediaResource,
  BaseMediaResource,
  SearchErrorEvent,
} from "src/app/state/models";
import { YtResource } from "../yt.model";
import { generateId } from "src/app/state/helpers";

export function formatToMediaResource(resource: any): MediaResource {
  if (!resource) {
    throw createSearchErrorEvent("Resource does not exist");
  }

  const id = generateId(),
    type = determineMediaResponseType(resource.id.kind),
    vendorId = extractVendorId(resource.id, type),
    vendor = MediaVendor.YouTube,
    title = resource.snippet.title,
    author = resource.snippet.channelTitle,
    imageUrl = resource.snippet.thumbnails.medium.url,
    description = resource.snippet.description;

  const base: BaseMediaResource = {
    id,
    vendorId,
    vendor,
    title,
    author,
    description,
    imageUrl,
  };

  if (type === MediaResourceType.Track) {
    const contentDetails = resource.contentDetails || {};
    const length = contentDetails.length || null;
    const isLive = resource.snippet.liveBroadcastContent === "live";
    return { ...base, type, isLive, length };
  }

  if (type === MediaResourceType.Playlist) {
    return {
      ...base,
      type,
      tracks: [],
      length: "0",
    };
  }

  throw createSearchErrorEvent("YouTube resource is of unknown type");
}

export function createSearchErrorEvent(error: string): SearchErrorEvent {
  return {
    vendor: MediaVendor.YouTube,
    message: error,
  };
}

export function determineYoutubeResourceType(
  type: MediaResourceType
): YtResource {
  return type === MediaResourceType.Playlist
    ? YtResource.Playlist
    : YtResource.Video;
}

export function determineMediaResponseType(
  vendorType: string
): MediaResourceType {
  if (vendorType.includes("video")) return MediaResourceType.Track;
  if (vendorType.includes("playlist")) return MediaResourceType.Playlist;
  throw createSearchErrorEvent("Unknown YouTube resource type encounetered");
}

export function determineDefaultMaxResults(resourceType: YtResource) {
  if (resourceType === YtResource.Playlist) return 5;
  return 20;
}

export function formatVideoDuration(duration: string) {
  const regex = duration.match(/[0-9]+/g);
  if (Array.isArray(regex)) {
    const result = regex
      .map((time, i) => `0${time}`.slice(i ? -2 : -1))
      .join(":");
    return result === "0" ? "0:00" : result;
  }
  return "--:--";
}

export function formatPlaylistDuration(itemCount: string) {
  return `${itemCount} tracks`;
}

function extractVendorId(itemId: any, type: MediaResourceType) {
  switch (type) {
    case MediaResourceType.Track:
      return itemId.videoId;
    case MediaResourceType.Playlist:
      return itemId.playlistId;
    default:
      throw createSearchErrorEvent(
        "Cannot generate YouTube vendorId. Unknown resource type."
      );
  }
}
