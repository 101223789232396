import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { MainPageComponent } from './main-page/main-page.component';

const routerConfig = [
  {
    path: '',
    component: MainPageComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routerConfig)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
