import {
  Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild,
  Renderer2, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter,
  HostBinding, HostListener, ChangeDetectorRef, OnChanges, SimpleChanges
} from '@angular/core';
import { fromEvent, Observable, merge } from 'rxjs';
import { switchMapTo, takeUntil, tap, takeWhile, skipLast, filter, map, debounce, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalSliderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  private _value = 0;
  @Input('value') set value(val: number) {
    if (!isNaN(val)) this._value = val;
    this.updateVisualState();
    this.cd.markForCheck();
  }
  get value() {
    return this._value;
  }

  @Output() change: EventEmitter<Number> = new EventEmitter();

  @HostBinding('attr.aria-valuemin') rangeStart = 0;
  @HostBinding('attr.aria-valuemax') rangeEnd = 100;
  @HostBinding('attr.aria-valuenow')
  get ariaValue() {
    return this.value;
  }

  width: number;
  leftOffset: number;
  thumbRadius = 6;
  thumbPercent: number;
  alive = true;


  // DETECT DRAG START
  startDrag = fromEvent(this.el.nativeElement, 'mousedown') as Observable<MouseEvent>;

  // DETECT DRAG STOP
  stopDrag = merge(
    fromEvent(document, 'mouseup'),
    fromEvent(document, 'click')
  );

  // DETECT CLICK EVENT
  clickEvent = fromEvent(this.el.nativeElement, 'mousedown').pipe(
    map(this.mapCoordToPercent.bind(this))
  );

  // DETECT DOCUMENT RESIZE
  documentResizeEvent = fromEvent(window, 'resize');

  // DRAG PERCENTAGE
  dragPercent = fromEvent(document, 'mousemove').pipe(
    tap(e => e.preventDefault()),
    map(this.mapCoordToPercent.bind(this)),
    takeUntil(this.stopDrag),
    skipLast(1)
  );


  @ViewChild('track', { static: true }) track: ElementRef<HTMLDivElement>;
  @ViewChild('thumb', { static: true }) thumb: ElementRef<HTMLDivElement>;
  @ViewChild('fill', { static: true }) fill: ElementRef<HTMLDivElement>;

  constructor(private el: ElementRef<HTMLDivElement>, private renderer: Renderer2, private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngAfterViewInit() {
    this.updateDimensions();
    this.updateState(this.value, false);

    // initiate drag detection
    this.startDrag.pipe(
      tap(this.updateDimensions.bind(this)),
      switchMapTo(this.dragPercent),
      tap(this.updateState.bind(this)),
      takeWhile(() => this.alive)
    ).subscribe();

    // initiate click detection
    this.clickEvent.pipe(
      tap(this.updateState.bind(this)),
      takeWhile(() => this.alive)
    ).subscribe();

    // keep updating width and offset on resize
    this.documentResizeEvent.pipe(
      takeWhile(() => this.alive),
      debounceTime(200)
    ).subscribe(this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.width = this.el.nativeElement.getBoundingClientRect().width;
    this.leftOffset = this.getLeftOffset(this.el.nativeElement);
    this.thumbPercent = (this.thumbRadius / this.width * 100) || 0;
    this.updateVisualState();
  }

  updateState(percent: number, emitChange: boolean = true) {
    this.value = percent;
    if (emitChange) this.change.emit(percent);
  }

  updateVisualState() {
    let clampCx: string;
    const progressPercent = `${this.value}%`;

    if (this.value === 0  || this.value === 100) {
      clampCx = this.value === 0 ? `${this.thumbRadius}px` : `calc(100% - ${this.thumbRadius}px)`;
    } else {
      clampCx = `${this._clamp(this.value, this.thumbPercent, 100 - this.thumbPercent)}%`;
    }

    this.renderer.setAttribute(this.thumb.nativeElement, 'cx', clampCx);
    this.renderer.setAttribute(this.fill.nativeElement, 'width', progressPercent);
  }

  ngOnDestroy() { this.alive = false; }

  private mapCoordToPercent(e: MouseEvent): number {
    const value = this._clamp(e.clientX - this.leftOffset, 0, this.width);
    const percent = Number((value / this.width).toFixed(2)) * 100;
    return Math.round(percent);
  }

  // SOME HELPER METHODS
  private getLeftOffset(el: HTMLElement) {
    let offset = 0;
    while (el) {
      const leftOffset = el.offsetLeft;
      if (!isNaN(leftOffset)) offset += el.offsetLeft;
      el = el.offsetParent as HTMLElement;
    }
    return offset;
  }

  private _clamp(value: number, min = 0, max = 100) {
    return Math.max(min, Math.min(value, max));
  }
}
