import { Injectable, Renderer2, RendererFactory2, NgZone } from "@angular/core";
import { from, Observable, BehaviorSubject } from "rxjs";
import { YouTubePlayer } from "../yt.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class YtPlayerApiService {
  private renderer: Renderer2;
  public ready$ = new BehaviorSubject<boolean>(false);
  public api: any;

  constructor(private rendererFactory: RendererFactory2, private zone: NgZone) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private bootstrap() {
    const apiReady = (resolve, reject) => {
      // CREATE SCRIPT TAG
      const tag: HTMLScriptElement = this.renderer.createElement("script");
      tag.onerror = (e) => reject(e);
      tag.src = `https://www.youtube.com/iframe_api`;

      // ATTACH IT AFTER FIRST SCRIPT ON PAGE
      const firstScriptTag = this.renderer.selectRootElement("script");
      this.renderer.insertBefore(
        firstScriptTag.parentNode,
        tag,
        firstScriptTag
      );

      // LISTEN FOR SUCCESS EVENT
      if (window) {
        window["onYouTubeIframeAPIReady"] = () => {
          this.ready$.next(true);
          this.api = window["YT"];
          resolve(window["YT"]);
        };
      }
    };

    return new Promise(apiReady);
  }

  public createPlayer(id, config): Observable<YouTubePlayer> {
    return from(
      this.bootstrap().then((api: any) => new api.Player(id, config))
    );
  }
}
