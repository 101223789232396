<!-- VISUALS -->
<div class="screen">
  <div class="empty-player-screen" *ngIf="!player.currentTrack" @opacityToggle>
    Pair some music with the rain sounds. It will show up here.
  </div>

  <div [ngClass]="[player.currentTrack?.vendor || '', 'active-player-screen']">
    <div class="yt">
      <div id="{{ ytId }}"></div>
    </div>

    <div class="error" *ngIf="player.playerErrors | async as error">
      <span class="body-1">{{ error.error.message }}</span>
      <button (click)="retry(error)">RETRY</button>
    </div>
  </div>
</div>

<!-- CONTROLS -->
<div class="controls">
  <horizontal-slider
    class="progress-slider"
    [value]="(player.progress$ | async) * 100"
    (change)="setProgress($event)"
  ></horizontal-slider>

  <button class="play-pause button-1" (click)="controlPlayback()">
    <ng-container [ngSwitch]="playing">
      <!-- PLAY -->
      <svg
        *ngSwitchCase="false"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <polygon
          id="ion-ios-play---Ionicons"
          fill="#ECECEC"
          points="6 2 21 12 6 22"
        ></polygon>
      </svg>
      <!-- PAUSE -->
      <svg
        *ngSwitchCase="true"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M4,22 L4,2 L9,2 L9,22 L4,22 Z M15,22 L15,2 L20,2 L20,22 L15,22 Z"
          id=""
          fill="#ECECEC"
        ></path>
      </svg>
    </ng-container>
  </button>

  <div class="track-info">
    <h3
      class="subheading-4 ellipsis"
      [innerHTML]="player.currentTrack?.title || 'Track name'"
    ></h3>
    <h4
      class="subheading-4 ellipsis"
      [innerHTML]="player.currentTrack?.author || 'Track artist'"
    ></h4>
  </div>

  <div class="secondary-controls">
    <!-- SKIP BUTTON -->
    <button
      class="skip-button"
      (click)="scheduler.dequeue()"
      [disabled]="!scheduler.queue.length"
    >
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M15.984,6 L18,6 L18,18 L15.984,18 L15.984,6 Z M6,18 L6,6 L14.496,12 L6,18 Z"
          id="skip_next---material"
          fill="#ECECEC"
        ></path>
      </svg>
    </button>

    <!-- VOLUME -->
    <button
      class="volume-button"
      (mouseenter)="showVolume = true"
      (mouseleave)="showVolume = false"
      [disabled]="!player.currentTrack"
    >
      <ng-container [ngSwitch]="volLevel(player.volume$ | async)">
        <!-- VOLUME OFF -->
        <svg
          *ngSwitchCase="0"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @opacityToggle
        >
          <path
            d="M12,3.984 L12,8.208 L9.888,6.096 L12,3.984 Z M4.272,3 L21,19.728 L19.728,21 L17.664,18.936 C16.463994,19.8640046 15.2480062,20.4719986 14.016,20.76 L14.016,18.696 C14.816004,18.4719989 15.5679965,18.0800028 16.272,17.52 L12,13.272 L12,20.016 L6.984,15 L3,15 L3,9 L7.728,9 L3,4.272 L4.272,3 Z M18.984,12 C18.984,10.9599948 18.7720021,9.9800046 18.348,9.06 C17.9239979,8.1399954 17.3360038,7.35200328 16.584,6.696 C15.8319962,6.03999672 14.9760048,5.57600136 14.016,5.304 L14.016,3.24 C15.3440066,3.52800144 16.5399947,4.09599576 17.604,4.944 C18.6680053,5.79200424 19.495997,6.815994 20.088,8.016 C20.696003,9.26400624 21,10.591993 21,12 C21,12.7360037 20.9120009,13.4599964 20.736,14.172 C20.5599991,14.8840036 20.3040017,15.5519969 19.968,16.176 L18.48,14.616 C18.8160017,13.7679958 18.984,12.8960045 18.984,12 Z M16.512,12 C16.512,12.2880014 16.4960002,12.4879994 16.464,12.6 L14.016,10.176 L14.016,7.968 C14.7840038,8.35200192 15.3919978,8.89999644 15.84,9.612 C16.2880022,10.3240036 16.512,11.1199956 16.512,12 Z"
            id="volume_off---material"
            fill="#ECECEC"
          ></path>
        </svg>

        <!-- VOLUME LOW -->
        <svg
          *ngSwitchCase="1"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @opacityToggle
        >
          <polygon
            id="volume_mute---material"
            fill="#ECECEC"
            points="7 9.016 11.032 9.016 16 4 16 20.032 11.032 15.016 7 15.016"
          ></polygon>
        </svg>

        <!-- VOLUME MID -->
        <svg
          *ngSwitchCase="2"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @opacityToggle
        >
          <path
            d="M5,9.016 L8.984,9.016 L14,4 L14,20.032 L8.984,15.016 L5,15.016 L5,9.016 Z M18.512,12.016 C18.512,12.8960044 18.2800023,13.6919964 17.816,14.404 C17.3519977,15.1160036 16.7360038,15.6639981 15.968,16.048 L15.968,7.984 C16.7360038,8.36800192 17.3519977,8.91599644 17.816,9.628 C18.2800023,10.3400036 18.512,11.1359956 18.512,12.016 Z"
            id=""
            fill="#ECECEC"
          ></path>
        </svg>

        <!-- VOLUME HIGH -->
        <svg
          *ngSwitchCase="3"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @opacityToggle
        >
          <path
            d="M14.016,3 C15.3440066,3.28800144 16.5399947,3.85599576 17.604,4.704 C18.6680053,5.55200424 19.495997,6.575994 20.088,7.776 C20.696003,9.02400624 21,10.351993 21,11.76 C21,13.168007 20.696003,14.4959938 20.088,15.744 C19.495997,16.944006 18.6680053,17.9679958 17.604,18.816 C16.5399947,19.6640042 15.3440066,20.2319986 14.016,20.52 L14.016,18.456 C14.9760048,18.1839986 15.8319962,17.7200033 16.584,17.064 C17.3360038,16.4079967 17.9239979,15.6200046 18.348,14.7 C18.7720021,13.7799954 18.984,12.8000052 18.984,11.76 C18.984,10.7199948 18.7720021,9.7400046 18.348,8.82 C17.9239979,7.8999954 17.3360038,7.11200328 16.584,6.456 C15.8319962,5.79999672 14.9760048,5.33600136 14.016,5.064 L14.016,3 Z M16.512,11.76 C16.512,12.6400044 16.2880022,13.4359964 15.84,14.148 C15.3919978,14.8600036 14.7840038,15.4079981 14.016,15.792 L14.016,7.728 C14.7840038,8.11200192 15.3919978,8.65999644 15.84,9.372 C16.2880022,10.0840036 16.512,10.8799956 16.512,11.76 Z M3,8.76 L6.984,8.76 L12,3.744 L12,19.776 L6.984,14.76 L3,14.76 L3,8.76 Z"
            id="volume_up---material"
            fill="#ECECEC"
          ></path>
        </svg>
      </ng-container>

      <!-- VOLUME SLIDER -->
      <div class="volume-slider-container" *ngIf="showVolume" @opacityToggle>
        <horizontal-slider
          class="volume-slider"
          [value]="player.volume$ | async"
          (change)="setVolume($event)"
        ></horizontal-slider>
      </div>
    </button>
  </div>
</div>
