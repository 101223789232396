import { Component, OnInit } from "@angular/core";
import { SchedulerService } from "../state/services/scheduler.service";

@Component({
  selector: "side-panel",
  templateUrl: "./side-panel.component.html",
  styleUrls: ["./side-panel.component.scss"],
})
export class SidePanelComponent implements OnInit {
  constructor(public scheduler: SchedulerService) {}
  rain = 0.16;
  thunder = 0.64;
  fire = 0.24;

  ngOnInit() {}

  tabChanged() {}
}
