import { MediaResource, BaseMediaResource, MediaResourceType, MediaVendor } from 'src/app/state/models';
import { generateId } from 'src/app/state/helpers';

export function formatToMediaResource(resource: any): MediaResource {
  const id = generateId(),
        type = determineMediaResponseType(resource.kind),
        vendorId = resource.id,
        vendor = MediaVendor.SoundCloud,
        title = resource.title,
        author = resource.user.username,
        imageUrl = artworkUrl(resource.artwork_url),
        description = resource.description;


  const base: BaseMediaResource = {
    id, vendorId, vendor, title, author, description, imageUrl
  };

  if (type === MediaResourceType.Track) {
    const length = formatTrackDuration(resource.duration);
    const isLive = false;
    return { ...base, type, isLive, length };
  }

  if (type === MediaResourceType.Playlist) {
    const length = `${resource.track_count} tracks`;
    return { ...base, type, tracks: [], length };
  }
}

function determineMediaResponseType(type: string): MediaResourceType {
  return type === 'track' ? MediaResourceType.Track : MediaResourceType.Playlist;
}

function formatTrackDuration(duration: number = 0) {
  const hours = Math.floor(duration / 3600000);
  const minutes = Math.floor(duration / 60000) - hours * 60;
  const seconds = Math.floor(duration / 1000) - (hours * 3600 + minutes * 60);

  let result = '';
  if (hours > 0) result += `${`00${hours}`.slice(-2)}:`;
  return result + `${`00${minutes}`.slice(-2)}:${`00${seconds}`.slice(-2)}`;
}

function artworkUrl(url: string) {
  if (!url) return '/assets/sc-placeholder.png';
  return url.replace('large', 't500x500');
}
