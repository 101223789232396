import {
  Component,
  OnInit,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { TabComponent as Tab } from './tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterContentInit {

  @Output() tabChange = new EventEmitter();
  @ContentChildren(Tab) tabs: QueryList<Tab>;
  @ViewChild('linkList', {static: true}) list: ElementRef<HTMLUListElement>;
  activeWidth: string;
  activeLeftOffset: string;

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, false);
    }
  }

  selectTab(tab: Tab, focusTabLink = true) {
    this.tabs.toArray().forEach(t => t.active = false);
    tab.active = true;
    this.tabChange.emit({
      title: tab.tabTitle,
      tab
    });

    setTimeout(() => {
      const active: HTMLElement = this.list.nativeElement.querySelector('.active');
      if (active) {
        if (focusTabLink) (active.firstChild as HTMLAnchorElement).focus();
        this.activeWidth = `${active.offsetWidth}px`;
        this.activeLeftOffset = `${active.offsetLeft}px`;
      }
    });
  }

  transitionTab(event: KeyboardEvent, index: number) {
    const tabs = this.tabs.toArray();
    switch (event.key) {
      case "ArrowLeft":
        if (index < 1) return;
        this.selectTab(tabs[index - 1]);
        break;
      case "ArrowRight":
        if (index >= tabs.length - 1) return;
        this.selectTab(tabs[index + 1]);
        break;
    }
  }
}
