import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
  Input,
} from "@angular/core";
import { MediaVendor } from "src/app/state/models";

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent {
  inputActive = false;
  @Input() q: string;
  @Input() vendor: MediaVendor;
  @Input() loading: boolean;
  @Output() search = new EventEmitter<{ query: string }>();
  @Output() chooseVendor = new EventEmitter<{ vendor: MediaVendor }>();

  @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;
  @HostListener("click", ["$event.target"]) focusInput(target: HTMLElement) {
    if (!this.searchInput) return;
    while (target) {
      if (target.tagName === "SUBMIT-BTN") return;
      target = target.parentElement;
    }
    this.searchInput.nativeElement.focus();
  }

  get placeholder() {
    return `Search ${this.vendor}`;
  }

  constructor() {}

  submit(query) {
    this.search.next({ query });
  }

  pickVendor(vendor: MediaVendor) {
    this.chooseVendor.next({ vendor });
  }

  doFocus() {
    this.inputActive = true;
  }

  doBlur() {
    this.inputActive = false;
    this.searchInput.nativeElement.blur();
  }
}
