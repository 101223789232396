import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SidePanelComponent } from "./side-panel/side-panel.component";
import { SearchBarComponent } from "./main-content/search-bar/search-bar.component";
import { VendorIconComponent } from "./main-content/search-bar/vendor-icon/vendor-icon.component";
import { SubmitBtnComponent } from "./main-content/search-bar/submit-btn/submit-btn.component";
import { SearchResultsComponent } from "./main-content/search-results/search-results.component";
import { LoadMoreButtonComponent } from "./main-content/search-results/load-more-button/load-more-button.component";
import { MainContentComponent } from "./main-content/main-content.component";
import { LoaderIconComponent } from "./shared/loader-icon/loader-icon.component";
import { AppRoutingModule } from "./app-routing.module";
import { MainPageComponent } from "./main-page/main-page.component";
import { TabsComponent } from "./shared/tabs/tabs.component";
import { TabComponent } from "./shared/tabs/tab/tab.component";
import { PlayerComponent } from "./side-panel/player/player.component";
import { HorizontalSliderComponent } from "./shared/horizontal-slider/horizontal-slider.component";
import { TrackListSmallComponent } from "./side-panel/pending-songs/pending-songs.component";
import { RoundSliderComponent } from "./shared/round-slider/round-slider.component";
import { InvisiblePlayerComponent } from "./shared/invisible-player/invisible-player.component";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    SidePanelComponent,
    SearchBarComponent,
    VendorIconComponent,
    SubmitBtnComponent,
    SearchResultsComponent,
    LoadMoreButtonComponent,
    MainContentComponent,
    LoaderIconComponent,
    MainPageComponent,
    TabsComponent,
    TabComponent,
    PlayerComponent,
    HorizontalSliderComponent,
    TrackListSmallComponent,
    RoundSliderComponent,
    InvisiblePlayerComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
