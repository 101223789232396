<form
  class="search-bar"
  [ngClass]="{ active: inputActive }"
  #searchForm="ngForm"
  (submit)="submit(q)"
  novalidate
>
  <!-- VENDOR PICK -->
  <vendor-icon
    [selected]="vendor"
    (pickVendor)="pickVendor($event)"
  ></vendor-icon>

  <!-- INPUT -->
  <input
    class="ellipsis"
    type="text"
    name="search"
    [placeholder]="placeholder"
    [(ngModel)]="q"
    (focus)="doFocus()"
    (blur)="doBlur()"
    [attr.aria-label]="placeholder"
    #searchInput
    (keypress.esc)="doBlur()"
  />

  <!-- SUBMIT -->
  <ng-container [ngSwitch]="loading">
    <loader-icon *ngSwitchCase="true"></loader-icon>
    <submit-btn
      *ngSwitchDefault
      type="submit"
      [loading]="loading"
      [disabled]="!q"
    ></submit-btn>
  </ng-container>
</form>
