import { Injectable, Query } from '@angular/core';
import { MediaResourceProvider, MediaResource, SearchQuery, MediaResourceType } from 'src/app/state/models';
import { Observable, of, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import * as SC from "soundcloud";
import { tap, map } from 'rxjs/operators';
import { formatToMediaResource } from './sc.formatters';

@Injectable({
  providedIn: 'root'
})
export class ScSearchService implements MediaResourceProvider {

  constructor(private http: HttpClient) {
    // SC.initialize(<SC.InitializeOptions>{
    //   client_id: environment.soundCloud.clientId
    // });
  }

  search(query: SearchQuery): Observable<MediaResource[]> {
    const q = query.query;
    const limit = determineSearchLimit(query.type, query.maxResults);
    const client_id = environment.soundCloud.clientId;
    const url = `${environment.soundCloud.url}${query.type === MediaResourceType.Track ? '/tracks' : '/playlists'}`;
    const params = { q, limit, client_id };

    return this.http.get<any[]>(url, { params }).pipe(
      // tap(tracks => console.log('unformatted: ', tracks)),
      map((items: any[]) => items.map(formatToMediaResource)),
      // tap(tracks => console.log('formatted: ', tracks)),
    );
  }
}

function determineSearchLimit(type: MediaResourceType, maxResults: number): string {
  return maxResults ? maxResults + '' : '24';
}
