import { Injectable } from '@angular/core';
import { YtSearchService } from './yt-search/yt-search.service';
import { MediaResourceProvider, MediaResourcePlayer, MediaBackend } from '../../models';
import { YtPlayerService } from './yt-player/yt-player.service';

@Injectable({
  providedIn: 'root'
})
export class YtWrapperService implements MediaBackend {

  constructor(private _search: YtSearchService, private _player: YtPlayerService) { }

  get search(): MediaResourceProvider {
    return this._search;
  }

  get player(): MediaResourcePlayer {
    return this._player;
  }
}
