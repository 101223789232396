import { Observable, Subject, Observer, BehaviorSubject } from 'rxjs';
import { Track, SearchQuery, MediaResource, MediaVendor } from './entities.model';


/**
 *
 * MEDIA RESOURCE PROVIDER MODELS
 *
 */

export interface SearchLoadEvent {
  vendor: MediaVendor;
  loading: boolean;
}

export interface SearchErrorEvent {
  vendor: MediaVendor;
  message: string;
}

export type SearchMetaEvent = SearchLoadEvent | SearchErrorEvent;

export interface QueryCache {
  [queryId: string]: {
    expiresAt: number;
    results: MediaResource[];
  };
}

export interface MediaResourceProvider {
  search(query: SearchQuery): Observable<MediaResource[]>;
}


/**
 *
 * MEDIA RESOURCE PLAYER MODELS
 *
 */

export enum PlayerState {
  UNAVAILABLE = -2,
  EMPTY = -1,
  LOADING = 0,
  CUED = 1,
  PLAYING = 2,
  PAUSED = 3,
  STOPPED = 4,
  ENDED = 5
}

export enum PlayerErrorType {
  LOAD_API_FAIL = -1,
  INVALID_RESOURCE_ID = 0,
  PLAYBACK_ERROR = 1,
  NONEXISTENT_RESOURCE = 2,
  PLAYBACK_NOT_ALLOWED = 3,
  UNKNOWN = 4
}

export interface PlayerError {
  vendor: MediaVendor;
  type: PlayerErrorType;
  error?: Error;
  track?: Track;
  retry?(): void;
}

export interface MediaResourcePlayer {

  /** PLAYER STATE INFO */
  vendor: MediaVendor;
  track: Track;

  /** Number between 0 and 100 */

  /** Number between 0 and 1 */
  playerState: Subject<PlayerState>;
  playerErrors: Subject<PlayerError>;
  playerProgress: BehaviorSubject<number>;
  playerVolume: Subject<number>;

  /** PLAYER STATE CHANGE METHODS */
  cue(track: MediaResource): Promise<void>;
  play(track?: MediaResource): Promise<void>;
  stop(): void;
  pause(): void;
  setVolume(vol: number): void;
  seek(percent: number): void;
  startReporting(
    stateOberver: Observer<PlayerState>,
    errorObserver: Observer<PlayerError>,
    volumeObserver: Observer<number>,
    progressObserver: Observer<number>): Promise<void>;
  stopReporting(): void;
}


/**
 *
 * MEDIA BACKEND MODEL
 *
 */

export interface MediaBackend {
  search: MediaResourceProvider;
  player: MediaResourcePlayer;
}
